
*{
  margin: 0;
  padding: 0;
  user-select:none;
}

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(20, 20, 20);
}

.board {
  width: calc(64px*8);
  height: calc(64px*8);
  display: flex;
  flex-wrap: wrap;
  border: 4mm ridge rgba(63, 63, 63, 0.6);
}

.cell {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell img {
  width: 48px;
  height: 48px;
  position: relative;
}

.black{
 background-color: rgb(38, 63, 71);
}

.white{
  background-color: #beb2a2;
}

.selected{
  background-color: rgb(218, 193, 81);
}

.available{
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #22c022;
}

.lost{
  height: calc(50vh - 60px);
  padding: 30px;
  margin-left: 50px;
  background-color: lightgrey;
}